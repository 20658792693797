<template>
  <div id="app">
    <div class="home" ref="home" style="padding-top:30px;">
      <div class="d-flex align-items-center justify-content-between" style="margin: 0px 24px;">
        <div class="company-block">
          <img :src="companyInfo.logo" />
          <span class="company-name">{{ companyInfo.name }}</span>
        </div>
        <div class="page-status text-center">
          目前為第
          {{ page.index > totalPage ? totalPage : page.index }}頁，總共{{
            totalPage
          }}頁
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center" style="margin: 80px 24px;">
        <div class="status-block">
          <div class="d-flex">
            <div
              v-for="(char, index) in peopleStatusSplited.onSite"
              :key="index"
              class="status-num"
            >
              <span>{{ char }}</span>
            </div>
          </div>
          <p class="status-title">現場人數</p>
        </div></div>
      <div class="d-flex align-items-center justify-content-between" style="margin: 80px 24px;">
        <div class="status-block">
          <div class="d-flex">
            <span
              v-for="(char, index) in peopleStatusSplited.total"
              :key="index"
              class="status-num"
            >
              {{ char }}
            </span>
          </div>
          <p class="status-title">今日進場</p>
        </div>
        <div class="status-block">
          <div class="d-flex">
            <span
              v-for="(char, index) in peopleStatusSplited.BACK_DOOR"
              :key="index"
              class="status-num"
            >
              {{ char }}
            </span>
          </div>
          <p class="status-title">今日離場</p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center row" style="margin: 20px auto;">
        <div
          class="col-6"
          v-for="groupInfo in groupDataByPage[page.index - 1]"
          :key="groupInfo.group"
        >
          <div class="group-block" v-if="groupInfo.group !== 'NO_GROUP'">
            <p class="group-name">
              {{ groupInfo.group }}
            </p>
            <p class="group-onsite">
              {{ formatNumber(groupInfo.onSite) }}
            </p>
          </div>
        </div>
      </div>
      <div class="time-status text-end">
        {{ timestamp }}
      </div>
    </div>
  </div>
</template>

<script>
import { getPeopleCount, getCompanyLogo } from '@/api/index.js'
import { mixinWebsocket } from '@/utils/ws'

export default {
  name: 'HomeView',
  mixins: [mixinWebsocket],
  data () {
    return {
      peopleStatus: {},
      page: { limit: 8, index: 1 },
      totalPage: 0,
      companyInfo: {},
      timestamp: '',
      employeeNextStatusData: [],
      groupData: [],
      groupDataByPage: [],
      peopleStatusSplited: {}
    }
  },
  async created () {
    this.initWebsocket() // 開啓前後端的websocket通道
    setInterval(() => {
      this.getNow()
    }, 1000)

    getCompanyLogo({})
      .then((res) => {
        this.companyInfo = res.data
      })
      .catch((error) => {
        console.log(error)
      })
    await this.getPeopleCount()
    setInterval(() => {
      if (this.page.index < this.totalPage) {
        this.page.index++
      } else {
        this.page.index = 1
      }
    }, 5000)
  },
  mounted () {},
  destroy () {
    this.websocketclose() // 關閉websocket通道
  },
  watch: {
    '$store.state.stateChange': function () {
      this.getPeopleCount()
    }
  },
  methods: {
    splitNumber () {
      Object.keys(this.peopleStatus).forEach((key) => {
        const numToString = this.formatNumber(this.peopleStatus[key]).trim()
        this.peopleStatusSplited[key] = numToString.split('')
      })
    },
    formatNumber (num) {
      if (num < 10) {
        return '000' + num.toString()
      } else if (num < 100) {
        return '00' + num.toString()
      } else if (num < 1000) {
        return '0' + num.toString()
      } else {
        return num.toString()
      }
    },
    getNow: function () {
      const today = new Date()
      const date =
        today.getFullYear() +
        '/' +
        (today.getMonth() + 1 < 10 ? '0' : '') +
        (today.getMonth() + 1) +
        '/' +
        today.getDate()
      const time =
        (today.getHours() < 10 ? '0' : '') +
        today.getHours() +
        ':' +
        (today.getMinutes() < 10 ? '0' : '') +
        today.getMinutes() +
        ':' +
        (today.getSeconds() < 10 ? '0' : '') +
        today.getSeconds()
      const dateTime = date + ' ' + time
      this.timestamp = dateTime
    },
    getPeopleCount () {
      getPeopleCount({})
        .then((res) => {
          this.peopleStatus = res.data.peopleStatus
          this.peopleStatus.total = this.peopleStatus.FRONT_DOOR + this.peopleStatus.BACK_DOOR
          this.splitNumber()
          this.groupData = res.data.companyList
          this.totalPage = Math.ceil(this.groupData.length / this.page.limit)
          this.groupDataByPage = this.splitArrayIntoChunksOfLen(
            this.groupData,
            8
          )
        })
        .catch((error) => {
          console.log(error)
        })
    },
    splitArrayIntoChunksOfLen (arr, len) {
      const chunks = []
      let i = 0
      const n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)))
      }
      return chunks
    }
  }
}
</script>

<style lang="scss">
#app{
  overflow: hidden;
  width: 1080px;
  height: 1920px;
  background: #7dbff9;
}
.home{
  background: #7dbff9;
  font-weight: 300;
  height: 100vh;
}
.page-status {
  font-size: 40px;
}
.time-status{
  font-size: 40px;
  position: fixed;
  bottom: 20px;
  right: 50px;
}
.company-block {
  display: flex;
  align-items: center;
  img {
    height: 60px;
  }
  .company-name {
    font-size: 40px;
  }
}
.status-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  .status-num {
    font-size: 150px;
    background: linear-gradient(
      180deg,
      rgba(57, 57, 57, 1) calc(50% - 1px),
      rgba(0, 0, 0, 0) calc(50%),
      rgba(57, 57, 57, 1) calc(50% + 1px)
    );
    padding: 10px 6px;
    border: #fff 6px solid;
    border-radius: 15px;
    color: #fff;
    margin: 0 2px;
    line-height: 1;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 13px;
      height: 20px;
      background: linear-gradient(#393929, #fff);
      border: #7dbff9 2px solid;
      top: 50%;
      left: -9px;
      transform: translate(0%, -50%);
    }
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 13px;
      height: 20px;
      background: linear-gradient(#393929, #fff);
      border: #7dbff9 2px solid;
      top: 50%;
      right: -9px;
      transform: translate(0%, -50%);
    }
  }
  .status-title {
    font-size: 60px;
    color: #fff;
  }
}
.group-block {
  width: 335px;
  height: 230px;
  background: url("./assets/group-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  margin: auto;
  .group-name {
    text-align: left;
    padding-top: 90px;
    font-size: 62px;
    margin-bottom: 0;
    line-height: 1;
    padding-left: 25px;
  }
  .group-onsite {
    text-align: end;
    font-size: 42px;
    margin-bottom: 0;
    padding-top: 4px;
    padding-right: 38px;
  }
}
</style>

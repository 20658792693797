import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stateChange: 0
  },
  mutations: {
    setStateChange (state) {
      const oriStateChange = state.stateChange + 1
      Vue.set(state, 'stateChange', oriStateChange)
    }
  },
  actions: {},
  modules: {}
})
